import React from "react";
import { useOverrides } from "@quarkly/components";
import { Text, Link, Box, Section } from "@quarkly/widgets";
const defaultProps = {
	"background": "--color-darkL1",
	"padding": "50px 0 50px 0",
	"quarkly-title": "Footer-19"
};
const overrides = {
	"box": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"justify-content": "space-between",
			"border-color": "#232a44",
			"md-flex-direction": "column"
		}
	},
	"box1": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"md-margin": "0px 0px 15px 0px",
			"sm-flex-direction": "column",
			"justify-content": "flex-start",
			"align-items": "center",
			"grid-gap": "24px",
			"margin": "0px auto 0px auto"
		}
	},
	"box2": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"grid-gap": "8px"
		}
	},
	"text": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"font": "--base",
			"color": "white",
			"children": "Phone"
		}
	},
	"link": {
		"kind": "Link",
		"props": {
			"color": "--light",
			"text-decoration-line": "initial",
			"font": "normal 300 16px/1.5 --fontFamily-sans",
			"display": "block",
			"margin": "0px 0px 0px 0px",
			"sm-margin": "0px 0 5px 0px",
			"hover-color": "#a78bfa",
			"href": "+61249892719",
			"children": "+61249892719"
		}
	},
	"box3": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"grid-gap": "8px"
		}
	},
	"text1": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "white",
			"font": "--base",
			"children": "Email:"
		}
	},
	"link1": {
		"kind": "Link",
		"props": {
			"color": "--light",
			"text-decoration-line": "initial",
			"font": "normal 300 16px/1.5 --fontFamily-sans",
			"display": "block",
			"margin": "0px 0px 0px 0px",
			"hover-color": "#a78bfa",
			"href": "mailto:caspiancruises.fun",
			"children": "contact@caspiancruises.fun"
		}
	},
	"box4": {
		"kind": "Box",
		"props": {
			"display": "flex",
			"grid-gap": "8px"
		}
	},
	"text2": {
		"kind": "Text",
		"props": {
			"margin": "0px 0px 0px 0px",
			"color": "white",
			"font": "--base",
			"children": "Address"
		}
	},
	"link2": {
		"kind": "Link",
		"props": {
			"color": "--light",
			"text-decoration-line": "initial",
			"font": "normal 300 16px/1.5 --fontFamily-sans",
			"display": "block",
			"margin": "0px 0px 0px 0px",
			"hover-color": "#a78bfa",
			"children": "16 George St, Highfields NSW 2289, Australia"
		}
	}
};

const Footer = props => {
	const {
		override,
		children,
		rest
	} = useOverrides(props, overrides, defaultProps);
	return <Section {...rest}>
		<Box {...override("box")}>
			<Box {...override("box1")}>
				<Box {...override("box2")}>
					<Text {...override("text")} />
					<Link {...override("link")} />
				</Box>
				<Box {...override("box3")}>
					<Text {...override("text1")} />
					<Link {...override("link1")} />
				</Box>
				<Box {...override("box4")}>
					<Text {...override("text2")} />
					<Link {...override("link2")} />
				</Box>
			</Box>
		</Box>
		{children}
	</Section>;
};

Object.assign(Footer, { ...Section,
	defaultProps,
	overrides
});
export default Footer;